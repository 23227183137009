import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'

const Login = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("email", email);
        // console.log("password", password);
        if (!email || !password) {
            toast.dismiss()
            toast.error("Please Enter the details");
            return;
        }
        try {
            const requestData = {
                email, password,
            };
            let response = await _fetch(`${base_url}api/auth/signin`, 'POST', requestData, {})

            // console.log("data", response);
            if (response.success === 1) {
                localStorage.setItem("token", response.data?.accessToken)
                toast.success('Login successfully!')
                navigate('/dashboard')
            } else {
                toast.error(response.message)
            }

        }
        catch (error) {
            console.error('Error details:', error);
        }
    }

    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="login-page ">
                        <div className="heading text-center mb-3">
                            <h3>Future Bot</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" autoComplete='off' aria-describedby="emailHelp" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" id='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <div className="forget fw-bold mt-2 pt-1 mb-0">
                                    <div className="link-danger">Forgot Password?</div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>

                            <div>
                                <p className="small fw-bold mt-2 pt-1 mb-0">
                                    Don't have an account? <NavLink to="/signup" className="link-danger">Sign Up</NavLink>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login