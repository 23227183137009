import React from 'react'

const OpenPosition = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">User</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mode</th>
                                <th scope="col">Transaction</th>
                                <th scope="col"></th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OpenPosition