import React from 'react'

const Bindapi = () => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-6 mt-5">
                    <div className="bind_details">
                        <div className="rounded shadow p-2">
                            <div className="col">
                                <div className="heading">
                                    <h5>Precautions</h5>
                                </div>
                                <div className="heading-data">
                                    <p><span>1.</span>Please confirm that the API permission has checked Enable Spot And Margin Trading </p>
                                    <p><span>2.</span>Please enter the correct API,please do not enter special characters</p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded shadow p-2 mt-3">
                            <div className="heading">
                                <h5>Ip Group binding</h5>
                                <p>For security,Binance Exchange recommends binding the server IP address when created the API.For users
                                    who need to bind the IP address,click Edit permissions in the upper right corner after the API is created, and click in the IP address permision column to restrict access to only trusted IPs.</p>
                                <span>(Recommended) option,click the copy button to copy the IP group to the input box and click OK,after adding, click savein the upper right corner.</span>
                            </div>
                            <div className="form mb-4 mt-3">
                                <input type="text" className="form-control input" placeholder='165.232.181.116' />
                            </div>
                        </div>
                        <div className="rounded shadow p-2 mt-3">
                            <div class="mb-3 row">
                                <label for="api_key" class="col-sm-2 col-form-label">Api Key</label>
                                <div class="col-sm-10">
                                    <input type="text" className="form-control input" placeholder='Key' />
                                </div>
                            </div>
                            <div class="mb-2 row">
                                <label for="input_Password" className="col-sm-2 col-form-label">Secret Key</label>
                                <div class="col-sm-10">
                                    <input type="password" className="form-control input" id="inputPassword" placeholder='*************' />
                                </div>
                            </div>
                        </div>
                        <div class="d-grid mt-3">
                            <button class="btn btn-secondary" type="button">Unbind</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bindapi