import React from 'react'
import QRCode from 'react-qr-code'

const Deposit = () => {
    return (
        <>
            <div className="row mt-3">
                <div className="shadow mt-5 text-center ">
                    <div className="mb-3">
                        <h5> QR Code</h5>
                    </div>
                    <div className="">
                        <QRCode value='QR'></QRCode>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deposit
