import React from 'react'
import icon from "../assets/img/icon.svg"

const Dashboard = () => {
  return (
    <>
      <div class="row p-5 py-md-5">
        <div className="rounded shadow">
          <div class=" d-flex align-items-center justify-content-center">
            <div className="">
              <div class="p-3">
                <h5 class="mb-0">kush</h5>
              </div>
              <div className="p-3">
                <h6 class="mb-0">SN938394</h6>
              </div>
              {/* <div className="p-3">
              <p className='mb-0'>Rank:SENATOR</p>
            </div> */}
              <div className="p-3">
                <h6>My Cource Package:0 </h6>
              </div>
            </div>
            <div className="vr mt-3"></div>
            <div className="cr">
              <div class="p-3">
                <p class="mb-0">Earning Wallet</p>
              </div>
              <div className="p-3">
                <h6 class="mb-0">0</h6>
              </div>
              <div className="p-3">
                <p className='mb-0'>Activation Wallet</p>
              </div>
              <div className="p-3">
                <h6>00:000 </h6>
              </div>
            </div>
          </div>
          <hr></hr>
          {/* <div className="mb-3 text-right">
            <p>Copy referral link</p>
          </div> */}
        </div>
      </div>

      <div className="d-flex justify-content-between p-3">
        <img src={icon} alt="icon" />
        <button type='button' className='btn btn-success'>Deposit</button>
      </div>

      {/* <div className="">
        <div className="">
          <div className="">
            <p>Open Orders</p>
            <p>Positons</p>
          </div>
          <div className='form-group col-1'>
            <select className='form-control' >
              <option>Binace</option>
              <option>Binace</option>
              <option>Binace</option>
              <option>Binace</option>
              <option>Binace</option>
            </select>
          </div>        </div>
      </div> */}
    </>
  )
}

export default Dashboard