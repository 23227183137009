import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'

const Login = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    const [sponser, setSponser] = useState();
    const navigate = useNavigate();


    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!email || !password || !name || !sponser) {
            toast.dismiss()
            toast.error("Please Enter the details");
            return;
        }

        try {
            const signupData = {
                email: email,
                password: password,
                name: name,
                sponser_id: sponser,
            };
            let response = await _fetch(`${base_url}api/auth/signup`, 'POST', signupData, {})

            // console.log("response", response);
            if (response.success === 1) {
                toast.success(response.message);
                navigate('/');
            } else {
                toast.error(response.message);
            }

        }
        catch (error) {
            console.log("Error details", error);

        }
    }

    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="sign-page ">
                        <form >
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Name </label>
                                <input type="text" id="name" onChange={(e) => setName(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Sponser Id</label>
                                <input type="text" id="sponser" onChange={(e) => setSponser(e.target.value)} autoComplete='off' className="form-control" />
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={handleSignUp}>Submit</button>
                            <div>
                                <div className="small fw-bold mt-2 pt-1 mb-0">
                                    Have an account? <NavLink to='/' className="link-danger">Login</NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login