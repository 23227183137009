import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/css/Style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';

const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [openSection, setOpenSection] = useState(null);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    // Toggle sidebar
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const logout = () => {
        localStorage.clear('token');
        navigate('/');
    };

    // Toggle dropdowns
    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
            <div className="wrapper">
                <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
                    <div className="d-flex">
                        <button className="toggle-btn" type="button" onClick={handleToggle}>
                            <i class="bi bi-toggles"></i>
                        </button>
                        <div className="sidebar-logo">
                            <a href="#">Future Bot</a>
                        </div>
                    </div>
                    <ul className="sidebar-nav">
                        <li className="sidebar-item">
                            <NavLink to='/dashboard' className="sidebar-link">
                                <i className="bi bi-speedometer2" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="sidebar-item">
                            <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
                                <i class="bi bi-gear"></i>
                                <span>Settings</span>
                            </a>
                            <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
                                <li className="sidebar-item">
                                    <NavLink to='/bindapi' className='navlink'><span>Bind Api Key</span></NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink to='/deposit' href="#" className='navlink'>Deposit</NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className="sidebar-item">
                            <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
                                <i className="bi bi-person-circle   " />
                                <span>Trade</span>
                            </a>
                            <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
                                <li className="sidebar-item outcome">
                                    <NavLink to='/open_position' className='navlink sidebar-link collapsed'>
                                        Open positions
                                    </NavLink>
                                </li>
                                <li className="sidebar-item outcome">
                                    <NavLink to='/close_position' className='navlink sidebar-link collapsed'>
                                        Closed positions
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="sidebar-item">
                            <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
                                <i class="bi bi-box-arrow-left"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </aside>
            </div>


            <LogoutModal
                isOpen={isLogoutModalOpen}
                onCancel={() => setIsLogoutModalOpen(false)}
                onConfirm={() => {
                    setIsLogoutModalOpen(false);
                    logout();
                }}
            />
        </div>
    );
};

export default Sidebar;
