import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Dashboard from './Dashboard/Dashboard';
import Private from './Routing/Private';
import Public from './Routing/Public';
import { Toaster } from 'react-hot-toast';
import PrivateLayout from './Layouts/PrivateLayout';
import Bindapi from './Blindapi/Bindapi';
import Deposit from './Blindapi/Deposit';
import OpenPositon from "./Trade/OpenPosition"
import ClosePosition from './Trade/ClosePosition';


const App = () => {
  return (
    <>
      <Router>
        <Routes>

          {/* Private routes */}
          <Route element={<Private />}>
            <Route path="/dashboard" element={<PrivateLayout><Dashboard /></PrivateLayout>} />
            <Route path="/bindapi" element={<PrivateLayout><Bindapi /></PrivateLayout>} />
            <Route path="/deposit" element={<PrivateLayout><Deposit /></PrivateLayout>} />
            <Route path="/open_position" element={<PrivateLayout><OpenPositon /></PrivateLayout>} />
            <Route path="/close_position" element={<PrivateLayout><ClosePosition /></PrivateLayout>} />
          </Route>

          {/* Public routes */}
          <Route element={<Public />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>

    </>

  );
}

export default App;


