import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import 'bootstrap/dist/css/bootstrap.min.css';


const PrivateLayout = ({ children }) => {

    return (

        <div className="dashboard__wrap">
            <Sidebar />
            <div className='main__wrap'>
                {children}
            </div>
        </div>
    )
}

export default PrivateLayout
